<template>
  <div class="home-page">
    <div class="header-wrap">
      <div class="logo-wrap">
        <img src="/logo.png" class="logo" />
        <p>一秒·私人云服务</p>
      </div>
      <van-button type="primary" @click="goLogin">登录/注册</van-button>
    </div>
    <div class="intro-wrap">
      <h1>便捷好用的<br />个人互联网服务</h1>
      <p>个人爬虫服务/私密存储服务/GPT/AIGC服务/QQ机器人服务/知识库接口服务</p>
      <van-button type="primary" @click="goLogin">立即进入</van-button>
    </div>
    <div class="feature-wrap">
      <div class="feature-item">
        <div class="intro-section">
          <p class="title">个人爬虫服务</p>
          <p class="intro">
            当你想爬取一些互联网数据比如某关键词相关网盘等各类公开链接，我们将代替你一键生成爬取代码，并提供带宽服务器等服务帮你零开发完成爬取需求。
          </p>
        </div>
        <img src="../assets/images/spider_intro.png" class="image-section" />
      </div>
      <div class="feature-item">
        <img src="../assets/images/storage_intro.png" class="image-section" />
        <div class="intro-section">
          <p class="title">私密存储服务</p>
          <p class="intro">
            支持多端实时访问、速度快、安全便捷的私密存储服务，为你的个人数据提供最高级别的存储方案，协助你完成一辈子值得传承的家族数据资产。
          </p>
        </div>
      </div>
      <div class="feature-item">
        <div class="intro-section">
          <p class="title">智能GPT服务</p>
          <p class="intro">
            提供各项开源稳定的GPT接入方案，一键帮你生成可服务的智能GPT代码，用于你个人的问答机器人、文本汇总、内容生成等服务。
          </p>
        </div>
        <img src="../assets/images/gpt_intro.png" class="image-section" />
      </div>
      <div class="feature-item">
        <img src="../assets/images/bot_intro.png" class="image-section" />
        <div class="intro-section">
          <p class="title">QQ机器人服务</p>
          <p class="intro">
            提供QQ机器人的接入方案，帮你低代码完成私域机器人、公域机器人的接入，打造管理频道、群、私聊等你的专属机器人小帮手。
          </p>
        </div>
      </div>
      <div class="feature-item">
        <div class="intro-section">
          <p class="title">知识库服务</p>
          <p class="intro">
            提供成语、英语、诗词等多达百万词条的数据信息，方便你接入到各类场景，并能长期享受内容的更新迭代服务，后续将持续更新其他知识库。
          </p>
        </div>
        <img src="../assets/images/wiki_intro.png" class="image-section" />
      </div>
    </div>
    <div class="footer-wrap">
      <div class="logo-wrap">
        <img src="/logo.png" class="logo" />
        <p>一秒·私人云服务</p>
      </div>
      <p>©Copyright. All Rights Reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goLogin() {
      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.home-page {
  background-image: url("../assets/images/home_bg.png");
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
  background-color: #fbfcff;
  .logo-wrap {
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: bolder;
    img {
      height: 40px;
      margin-right: 10px;
    }
  }
  .header-wrap {
    max-width: 1000px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    .van-button {
      border-radius: 30px;
    }
  }
  .intro-wrap {
    padding: 80px 0;
    text-align: center;
    color: #170f49;
    h1 {
      font-size: 40px;
      margin-bottom: 20px;
    }
    .van-button {
      width: 200px;
      margin-top: 30px;
      border-radius: 40px;
    }
  }
  .feature-wrap {
    max-width: 1000px;
    margin: auto;
    .feature-item {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        margin-right: 40px;
      }
      .intro-section {
        color: #170f49;
        max-width: 400px;
        .title {
          font-size: 20px;
          margin-bottom: 20px;
          font-weight: bold;
        }
        .intro {
          font-size: 15px;
          opacity: 0.6;
        }
      }
      .image-section {
        width: 300px;
      }
    }
  }
  .footer-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    .logo-wrap {
      font-size: 16px;
      margin-bottom: 10px;
      img {
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 700px) {
  .home-page {
    .header-wrap {
      padding: 15px;
      .logo-wrap {
        font-size: 18px;
        img {
          height: 24px;
        }
      }
      .van-button {
        height: 36px;
        font-size: 12px;
      }
    }
    .intro-wrap {
      padding: 60px 20px;
      h1 {
        font-size: 30px;
      }
    }
    .feature-wrap {
      .feature-item {
        padding: 20px;
        // flex-direction: column;
        align-items: center;
        .intro-section {
          .title {
            font-size: 16px;
            margin-bottom: 10px;
          }
          .intro {
            font-size: 12px;
          }
        }
        .image-section {
          width: 120px;
        }
      }
    }
  }
}
</style>
